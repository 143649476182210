export const CREATE_EVM_ADDRESS_TX = `
import EVM from 0xEVM
import FungibleToken from 0xFungibleToken
import FlowToken from 0xFlow

/// Creates a COA and saves it in the signer's Flow account
///
transaction() {
  let auth: auth(IssueStorageCapabilityController, IssueStorageCapabilityController, PublishCapability, SaveValue, UnpublishCapability) &Account

  prepare(signer: auth(BorrowValue, IssueStorageCapabilityController, PublishCapability, SaveValue, UnpublishCapability) &Account) {
    self.auth = signer
  }

  execute {
    let coa <- EVM.createCadenceOwnedAccount()

    let storagePath = StoragePath(identifier: "evm")!
    let publicPath = PublicPath(identifier: "evm")!
    self.auth.storage.save<@EVM.CadenceOwnedAccount>(<-coa, to: storagePath)
    let addressableCap = self.auth.capabilities.storage.issue<&EVM.CadenceOwnedAccount>(storagePath)
    self.auth.capabilities.unpublish(publicPath)
    self.auth.capabilities.publish(addressableCap, at: publicPath)
  }
}
`
